import * as React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';
import Layout from '../components/shared/layout';

const PreviewPage = ({ isPreview, isLoading }) => {
	if (isPreview === false) return 'Not a preview!';

	return (
		<Layout>
			<p>Loading</p>
		</Layout>
	);
};

export default withPreviewResolver(PreviewPage, {
	repositoryName: 'flyingmonkeys',
	linkResolver: ({ node, key, link }) => (doc) => {
		if (doc.type === 'homepage') return `/`;
		if (doc.type === 'beers_page') return `/beers`;
		if (doc.type === 'brewery_page') return `/brewery`;
		if (doc.type === 'contact_page') return `/contact`;
		if (doc.type === 'taproom_page') return `/taproom`;
		if (doc.type === 'tours_page') return `/tours`;
		return `/beers/${doc.uid}`;
	},
});
